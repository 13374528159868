import { IconTheme } from '@/components/Icon/theme';
import { tw } from '@/utils/tw';

const Icon = tw.theme({
  extend: IconTheme,
  base: `rounded-full bg-gray-100 p-1.5 sm:p-3 [&_path]:fill-gray-700`,
  variants: {
    size: {
      small: `h-6 w-6 sm:h-9 sm:w-9`,
    },
  },
});

const Pagination = tw.theme({
  slots: {
    base: `mb-6 mt-10 flex w-full items-center justify-center gap-3 sm:gap-6`,
    button: `text-headline-3xs flex h-8 w-8 items-center justify-center p-2 data-[active=true]:rounded-full data-[active=true]:bg-gray-200 sm:h-9 sm:w-9 sm:text-headline-2xs`,
    dots: `text-headline-3xs sm:text-headline-2xs`,
  },
});

export default Object.assign(Pagination, { Icon });
