import { propClassName } from '@/styles/AuthorDetails';
import { ClassNameProp, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { useAuthorDetailsContext } from './AuthorDetails';

export interface AuthorDetailsSoMeGroupProps extends ComponentProps<'div'> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const AuthorDetailsSoMeGroupComponent: Component<AuthorDetailsSoMeGroupProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const context = useAuthorDetailsContext({ colors, size, variant });

  const colorsClassName = propClassName('soMe_group_colors', context.colors ?? context.variant);
  const sizeClassName = propClassName('soMe_group_size', context.size ?? context.variant);
  const variantClassName = propClassName('soMe_group_variant', context.variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <div className={componentClassName} {...props}>
      {children}
    </div>
  );
};
