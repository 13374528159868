import { Image, ImageProps } from '@/components/Image';
import { propClassName } from '@/styles/AuthorDetails';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { useState } from 'react';
import { useAuthorDetailsContext } from './AuthorDetails';

const imageFallback = `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=`;

export interface AuthorDetailsImageProps extends ExtendedStandaloneComponentProps<ImageProps> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const AuthorDetailsImageComponent: ExtendedStandaloneComponent<AuthorDetailsImageProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const context = useAuthorDetailsContext({ colors, size, variant });

  const { options, src: standaloneSrc, ...standaloneProps } = $standalone ?? {};
  const { $group, className: standaloneClassName, ...standaloneOptions } = options ?? {};
  const { className: groupClassName, ...groupProps } = $group ?? {};

  const [src, setSrc] = useState(standaloneSrc);

  const groupColorsClassName = propClassName('image_group_colors', context.colors ?? context.variant);
  const groupSizeClassName = propClassName('image_group_size', context.size ?? context.variant);
  const groupVariantClassName = propClassName('image_group_variant', context.variant);

  const groupComponentClassName = cn(groupColorsClassName, groupSizeClassName, groupVariantClassName, groupClassName);

  const colorsClassName = propClassName('image_colors', context.colors ?? context.variant);
  const sizeClassName = propClassName('image_size', context.size ?? context.variant);
  const variantClassName = propClassName('image_variant', context.variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <Image
      options={{
        $group: { className: groupComponentClassName, ...groupProps },
        className: componentClassName,
        onError: () => setSrc(imageFallback),
        ...standaloneOptions,
      }}
      {...{ src }}
      {...standaloneProps}
      {...props}
    />
  );
};
