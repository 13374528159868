import { tw } from '@/utils/tw';
import BasePaginationTheme from 'base/components/Pagination/theme';

const Icon = tw.theme({
  extend: BasePaginationTheme.Icon,
  base: `bg-cherry-blossom-100 [&_path]:fill-black`,
});

const Pagination = tw.theme({
  extend: BasePaginationTheme,
  slots: {
    button: `data-[active=true]:bg-cherry-blossom-300 text-headline-xs sm:text-headline-xs`,
    dots: `text-headline-xs sm:text-headline-xs`,
  },
});

export default Object.assign(Pagination, { Icon });
