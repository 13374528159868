import { Icon as StandaloneIcon } from '@/components/Icon';
import { PaginationTheme } from '@/components/Pagination/theme';
import { GenericSlot, GenericSlotFactory } from 'base/components/GenericSlot';

const $ = GenericSlotFactory({ theme: PaginationTheme });

const Base = $({ as: 'div', slot: 'base' });
const Button = $({ as: 'span', slot: 'button' });
const Dots = $({ as: 'div', slot: 'dots' });
const Icon = GenericSlot({ as: StandaloneIcon, theme: PaginationTheme.Icon });

export const Pagination = Object.assign(Base, {
  Button,
  Dots,
  Icon,
});
