import { propClassName } from '@/styles/AuthorDetails';
import { ClassNameProp, ClassNameProps, Component, ComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { ComponentContextFactory } from 'lib/contexts/ComponentContextFactory';
import { AuthorDetailsCaptionComponent } from './AuthorDetails.Caption';
import { AuthorDetailsDescriptionComponent } from './AuthorDetails.Description';
import { AuthorDetailsEmailComponent } from './AuthorDetails.Email';
import { AuthorDetailsHeadlineComponent } from './AuthorDetails.Headline';
import { AuthorDetailsImageComponent } from './AuthorDetails.Image';
import { AuthorDetailsSoMeComponent } from './AuthorDetails.SoMe';
import { AuthorDetailsSoMeGroupComponent } from './AuthorDetails.SoMe.Group';

export const {
  Context: AuthorDetailsContext,
  Provider: AuthorDetailsProvider,
  useComponentContext: useAuthorDetailsContext,
} = ComponentContextFactory<Pick<AuthorDetailsProps, keyof ClassNameProps>>();

export interface AuthorDetailsProps extends ComponentProps<'section'> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

const AuthorDetailsComponent: Component<AuthorDetailsProps> = ({
  children,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const colorsClassName = propClassName('colors', colors ?? variant);
  const sizeClassName = propClassName('size', size ?? variant);
  const variantClassName = propClassName('variant', variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, className);

  return (
    <AuthorDetailsProvider value={{ colors: colors ?? variant, size: size ?? variant, variant }}>
      <section className={componentClassName} {...props}>
        {children}
      </section>
    </AuthorDetailsProvider>
  );
};

export const AuthorDetails = Object.assign(AuthorDetailsComponent, {
  Caption: AuthorDetailsCaptionComponent,
  Description: AuthorDetailsDescriptionComponent,
  Headline: AuthorDetailsHeadlineComponent,
  Email: AuthorDetailsEmailComponent,
  Image: AuthorDetailsImageComponent,
  SoMe: Object.assign(AuthorDetailsSoMeComponent, {
    Group: AuthorDetailsSoMeGroupComponent,
  }),
});
