import { SoMe, SoMeProps } from '@/components/SoMe';
import { propClassName } from '@/styles/AuthorDetails';
import { ClassNameProp, ExtendedStandaloneComponent, ExtendedStandaloneComponentProps } from '@/types/component';
import { cn } from '@/utils/cn';
import { useAuthorDetailsContext } from './AuthorDetails';

export interface AuthorDetailsSoMeProps extends ExtendedStandaloneComponentProps<SoMeProps> {
  colors?: ClassNameProp<'default' | 'alternative'>;
  size?: ClassNameProp<'default' | 'alternative'>;
  variant?: ClassNameProp<'default' | 'alternative'>;
}

export const AuthorDetailsSoMeComponent: ExtendedStandaloneComponent<AuthorDetailsSoMeProps> = ({
  $standalone,
  className,
  colors,
  size,
  variant,
  ...props
}) => {
  const context = useAuthorDetailsContext({ colors, size, variant });

  const { options, ...standaloneProps } = $standalone ?? {};
  const { className: standaloneClassName, ...standaloneOptions } = options ?? {};

  const colorsClassName = propClassName('soMe_colors', context.colors ?? context.variant);
  const sizeClassName = propClassName('soMe_size', context.size ?? context.variant);
  const variantClassName = propClassName('soMe_variant', context.variant);

  const componentClassName = cn(colorsClassName, sizeClassName, variantClassName, standaloneClassName, className);

  return (
    <SoMe
      options={{
        className: componentClassName,
        ...standaloneOptions,
      }}
      {...standaloneProps}
      {...props}
    />
  );
};
