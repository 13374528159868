import { ImageProps } from '@/components/Image';
import { SoMeProps } from '@/components/SoMe';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { AuthorDetails, AuthorDetailsProps } from './AuthorDetails';
import { AuthorDetailsCaptionProps } from './AuthorDetails.Caption';
import { AuthorDetailsDescriptionProps } from './AuthorDetails.Description';
import { AuthorDetailsEmailProps } from './AuthorDetails.Email';
import { AuthorDetailsHeadlineProps } from './AuthorDetails.Headline';
import { AuthorDetailsImageProps } from './AuthorDetails.Image';
import { AuthorDetailsSoMeProps } from './AuthorDetails.SoMe';
import { AuthorDetailsSoMeGroupProps } from './AuthorDetails.SoMe.Group';

export interface StandaloneAuthorDetailsProps extends StandaloneComponentProps {
  caption?: ReactNode;
  email?: ReactNode;
  description?: ReactNode;
  image?: ImageProps;
  soMeList?: SoMeProps[];
  title?: ReactNode;
  options?: AuthorDetailsProps & {
    $caption?: AuthorDetailsCaptionProps;
    $description?: AuthorDetailsDescriptionProps;
    $headline?: AuthorDetailsHeadlineProps;
    $email?: AuthorDetailsEmailProps;
    $image?: AuthorDetailsImageProps;
    $soMe?: AuthorDetailsSoMeProps & {
      $group?: AuthorDetailsSoMeGroupProps;
    };
  };
}

export const StandaloneAuthorDetails: StandaloneComponent<StandaloneAuthorDetailsProps> = ({
  caption,
  email,
  description,
  image,
  soMeList,
  title,
  options,
  ...props
}) => {
  const {
    $caption: captionProps,
    $email: emailProps,
    $description: descriptionProps,
    $headline: headlineProps,
    $image,
    $soMe,
    ...baseProps
  } = options ?? {};

  const { $standalone: imageStandaloneProps, ...imageProps } = $image ?? {};

  const { $group: soMeGroupProps, $standalone: soMeStandaloneProps, ...soMeProps } = $soMe ?? {};

  return (
    <AuthorDetails {...baseProps} {...props}>
      {image && <AuthorDetails.Image $standalone={{ ...imageStandaloneProps, ...image }} {...imageProps} />}
      {title && <AuthorDetails.Headline {...headlineProps}>{title}</AuthorDetails.Headline>}
      {caption && <AuthorDetails.Caption {...captionProps}>{caption}</AuthorDetails.Caption>}
      {email && <AuthorDetails.Email {...emailProps}>{email}</AuthorDetails.Email>}
      {soMeList && soMeList.length > 0 && (
        <AuthorDetails.SoMe.Group {...soMeGroupProps}>
          {soMeList.map((soMe, index) => (
            <AuthorDetails.SoMe key={index} $standalone={{ ...soMeStandaloneProps, ...soMe }} {...soMeProps} />
          ))}
        </AuthorDetails.SoMe.Group>
      )}
      {description && <AuthorDetails.Description {...descriptionProps}>{description}</AuthorDetails.Description>}
    </AuthorDetails>
  );
};
